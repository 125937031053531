import PageHead from "./PageHead";
function AboutPage() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <PageHead title="Our Story" route="Home / About" />
      <p
        style={{ paddingBottom: "6rem", fontSize: "1.2rem", color: "#aba2a1" }}
      >
        In progress...
      </p>
    </div>
  );
}
export default AboutPage;
