import "./style/PageHead.css";
function PageHead(props) {
  return (
    <div className="page-head-container">
      <h1>{props.title}</h1>
      <h6>{props.route}</h6>
      <div className="page-head-background"></div>
    </div>
  );
}
export default PageHead;
