import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style/OfficerBlock.css";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
function OfficerBlock(props) {
  const [imageValid, setImageValid] = useState(true);
  useEffect(() => {
    if (props.imgSrc == null) setImageValid(false);
  }, [imageValid, props.imgSrc]);
  return (
    <div className="officer-container">
      <div className="office-div">
        <FontAwesomeIcon
          className={imageValid ? "style-hide" : "officer-icon"}
          icon={faUser}
        />
        <img
          className={imageValid ? "" : "style-hide"}
          src={props.imgSrc}
          alt="img"
        ></img>
      </div>
      <div>
        <p>{props.name}</p>
        <span>{props.position}</span>
      </div>
    </div>
  );
}
export default OfficerBlock;
