import "./style/EventsPage.css";
import PageHead from "./PageHead";
import { useState } from "react";
import EventPost from "./EventPost";
function EventsPage() {
  const [UpComingState, setUpComingState] = useState("events-active");
  const [PreviousState, setPreviousState] = useState("events-inactive");
  const [eventView, setEventView] = useState(true);
  const [eventViewNone, setEventViewNone] = useState(false);
  function UpComingHandle() {
    if (UpComingState === "events-inactive") {
      setUpComingState("events-active");
      setPreviousState("events-inactive");
      setEventView(true);
      setEventViewNone(false);
    }
  }
  function PreviousHandle() {
    if (PreviousState === "events-inactive") {
      setUpComingState("events-inactive");
      setPreviousState("events-active");
      setEventView(false);
      setEventViewNone(true);
    }
  }
  return (
    <div className="events-container">
      <PageHead title="Be part of making memories" route="Home / Events" />
      <div className="events-content">
        <div className="events-buttons">
          <button
            onClick={() => {
              UpComingHandle();
            }}
            id={UpComingState}
          >
            UpComing Events
          </button>
          <button
            onClick={() => {
              PreviousHandle();
            }}
            id={PreviousState}
          >
            Previous Events
          </button>
        </div>
        <div className={eventView ? "events-posts" : "event-view"}>
          <EventPost />
        </div>
        <div className={eventViewNone ? "none-style" : "event-view"}>NONE</div>
      </div>
    </div>
  );
}
export default EventsPage;
