import "./style/TeamText.css";
import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
function TeamText(ref) {
  const conatiner = useRef(null);
  const isInView = useInView(conatiner, { margin: "0px 0px -20px 0px" });
  const MainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      MainControls.start("visible");
    }
  }, [MainControls, isInView]);
  const pVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.2,
      },
    },
  };
  return (
    <motion.div
      ref={conatiner}
      variants={pVariants}
      initial="hidden"
      animate={MainControls}
      className="team-text-container"
    >
      <h3>Discover Chapter Teams</h3>
      <p>
        At ACM UOT we are organized into three different teams (committees),
        each with its own focus and mission. Our events are open to all, and we
        actively seek to accommodate the diverse interests of our members. We
        encourage everyone to explore new topics and participate in various
        activities.
      </p>
    </motion.div>
  );
}
export default TeamText;
