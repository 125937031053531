import "./style/Header.css";
import { React, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import acm from "./imgs/acm.png";
import acmShort from "./imgs/acmShort.svg";
function Header() {
  const [menuOpen, setOpen] = useState(false);
  const [isScroll, setScroll] = useState("header-div");
  const [headerScrolled, setscrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScroll("header-div header-scroll");
        setscrolled(true);
      } else if (headerScrolled === true) {
        setScroll("header-div header-no-scroll");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerScrolled]);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="header-container">
      <div className={isScroll}>
        <nav>
          <div>
            <NavLink
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to="/"
            >
              {" "}
              <img className="logo-style" src={acm} alt="logo" />
            </NavLink>
            <NavLink
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to="/"
              className="link-style"
            >
              {" "}
              <img className="short-logo" src={acmShort} alt="logo" />
            </NavLink>
          </div>
          <div className={menuOpen ? "nav-container open" : "nav-container"}>
            <div className="home-margin">
              <NavLink
                to="/"
                onClick={() => setOpen(!menuOpen)}
                className="link-style"
              >
                Home
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/Events"
                onClick={() => setOpen(!menuOpen)}
                className="link-style"
              >
                Events
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/Blog"
                onClick={() => setOpen(!menuOpen)}
                className="link-style"
              >
                Blog
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/Office"
                onClick={() => setOpen(!menuOpen)}
                className="link-style"
              >
                Office
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/About"
                onClick={() => setOpen(!menuOpen)}
                className="link-style"
              >
                About
              </NavLink>
            </div>
            <div>
              <NavLink
                to="/Contact"
                onClick={() => setOpen(!menuOpen)}
                className="link-style"
              >
                Contact
              </NavLink>
            </div>
          </div>
          <div className="menu-btn-container">
            <div>
              <a
                className="discord-btn"
                href="https://discord.gg/P7fQ3VaDeY"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="discord-icon" icon={faDiscord} />
                Discord
              </a>
            </div>
            <button className="btn-display" onClick={() => setOpen(!menuOpen)}>
              <FontAwesomeIcon
                icon={menuOpen ? faXmark : faBars}
                className="icon-style"
              />
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
