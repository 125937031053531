import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.css";
import App from "./App";
import EventsPage from "./EventsPage";
import BlogPage from "./BlogPage";
import OfficePage from "./OfficePage";
import Header from "./Header";
import Footer from "./Footer";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutPage from "./AboutPage";
import ContactPage from "./ContactPage";
import Page404 from "./Page404";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/home" element={<App />}></Route>
        <Route path="/Events" element={<EventsPage />}></Route>
        <Route path="/Blog" element={<BlogPage />}></Route>
        <Route path="/Office" element={<OfficePage />}></Route>
        <Route path="/About" element={<AboutPage />}></Route>
        <Route path="/Contact" element={<ContactPage />}></Route>
        <Route path="/*" element={<Page404 />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
