import PageHead from "./PageHead";
function ContactPage() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <PageHead title="Connect with us" route="Home / Contact" />
      <p
        style={{
          paddingBottom: "6rem",
          fontSize: "1.2rem",
          color: "#aba2a1",
          textAlign: "center",
          lineHeight: "2rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        In progress... <br />
        For now You can contact us by sending an email to uotacm@gmail.com
      </p>
    </div>
  );
}
export default ContactPage;
