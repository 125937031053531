import HomeImg from "./HomeImg.js";
import Sponsors from "./Sponsors.js";
import AboutAcm from "./AboutAcm.js";
import AboutChapter from "./AboutChapter.js";
import TeamText from "./TeamText.js";
import AcmTeam from "./AcmTeam.js";
import { useEffect, useRef } from "react";
import TechImage from "./imgs/techimage.svg";
import TechIcon from "./imgs/techicon.svg";
import TeachImage from "./imgs/teachimage.svg";
import TeachIcon from "./imgs/teachicon.svg";
import DesignImage from "./imgs/designimage.svg";
import DesignIcon from "./imgs/designicon.svg";
import { motion, useInView, useAnimation } from "framer-motion";
import "./style/App.css";
function App() {
  const TeamsOnClick = () => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      margin: "0px 0px -100px ,0px",
    });
  };
  const ref = useRef(null);
  const conatiner = useRef(null);
  const isInView = useInView(conatiner, { margin: "200px 0px 300px 0px" });
  const MainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      MainControls.start("visible");
    }
  }, [MainControls, isInView]);
  const pVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.2,
      },
    },
  };
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);
  return (
    <div className="App">
      <HomeImg handleClick={TeamsOnClick} />
      <Sponsors />
      <AboutAcm />
      <AboutChapter />
      <div ref={ref}>
        <TeamText />
      </div>
      <motion.div
        ref={conatiner}
        variants={pVariants}
        initial="hidden"
        animate={MainControls}
        className="teams-container"
      >
        <motion.div variants={pVariants}>
          <AcmTeam
            title={[
              `Our mission is to build a community of students passionate about
        back-end development, DevOps and Artificial Intelligence (AI) and create
        activities in these fields, including organizing hackathons game jams and more.`,
            ]}
            teamImage={TechImage}
            teamIcon={TechIcon}
            teamUrl="https://forms.gle/SmaE5FDCBW6eAnVC6"
          />
        </motion.div>
        <motion.div variants={pVariants}>
          <AcmTeam
            title={[
              `Our mission is to create and manage workshops that help students learn, and provide them with access to free and paid educational content on the Internet and other activities that support student self-education in the UOT community.`,
            ]}
            teamImage={TeachImage}
            teamIcon={TeachIcon}
            teamUrl="https://forms.gle/C9iXstp55r2r4x8ZA"
            teamStyle="green"
            btnTeamStyle="green-btn"
          />
        </motion.div>
        <motion.div variants={pVariants}>
          <AcmTeam
            title={[
              `Our mission is to design, manage and support the visual identity of ACM at UOT. We create the chapter's branding, event banners and other elements, while also organising workshops and events to teach UI/UX & Frontend to the UOT community.`,
            ]}
            teamImage={DesignImage}
            teamIcon={DesignIcon}
            teamUrl="https://forms.gle/uk5CvK5EVFMabFXS7"
            teamStyle="purple"
            btnTeamStyle="purple-btn"
          />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default App;
