import "./style/Footer.css";
import acmShort from "./imgs/acmShort.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
function Footer() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  function returnDate() {
    return new Date().getFullYear();
  }
  return (
    <div className="footer-container">
      <div className="footer-blocks">
        <div className="footer-style">
          <img src={acmShort} alt="acmIcon" />{" "}
        </div>
        <div className="footer-style">
          <h5>Useful Links</h5>
          <Link className="Link-style" to="/Events">
            Events
          </Link>
          <Link className="Link-style" to="/Blog">
            Blog
          </Link>
          <Link className="Link-style" to="/About">
            About
          </Link>
          <Link className="Link-style" to="/Contact">
            Contact
          </Link>
        </div>
        <div className="footer-style">
          <h5>Social Media</h5>
          <a
            href="https://facebook.com/acmuot"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a href="/">Instagram</a>
          <a href="/">LinkedIn</a>
          <a
            href="https://discord.gg/P7fQ3VaDeY"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
        </div>
        <div className="footer-style">
          <h5>Address</h5>
          <p>
            Department of Computer Science - <br />
            Faculty of Science at the University of Tripoli, <br />
            University Road, Al-Furnaje
            <br /> Tripoli, Libya
          </p>
        </div>
      </div>
      <hr />
      <div className="footer-copy">
        <p>Copyright &copy; ACM UOT {returnDate()}</p>
      </div>
    </div>
  );
}
export default Footer;
