import "./style/HomeImg.css";
import homeimage from "./imgs/homeimage.svg";
import spiderweb from "./imgs/spiderweb.svg";
import teamsicon from "./imgs/teamsicon.svg";
function HomeImg(props) {
  return (
    <div className="home-container">
      <div className="home-left">
        <div className="home-title">
          <h3>University of Tripoli</h3>
          <h1>
            ACM <wbr /> STUDENT CHAPTER
          </h1>
        </div>
        <div className="home-title-btn">
          <a
            href="https://forms.gle/rQULrqjXesqB3QDC8"
            target="_blank"
            rel="noreferrer"
          >
            Join Us!
          </a>
          <button onClick={props.handleClick}>
            Discover ACM teams
            <img src={teamsicon} alt="TEAM ICONS"></img>
          </button>
        </div>
      </div>
      <div className="home-right">
        <img className="home-image" src={homeimage} alt="home" />
      </div>
      <div className="home-background">
        <div className="div-background-web">
          <img
            src={spiderweb}
            alt="spiderBackground"
            className="background-web"
          ></img>
        </div>
        <div className="dots-background"></div>
      </div>
    </div>
  );
}

export default HomeImg;
