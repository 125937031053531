import PageHead from "./PageHead";
import OfficerBlock from "./OfficerBlock";
import majd from "./imgs/majd.jpg";
import yassen from "./imgs/yassen.jpg";
import soliman from "./imgs/soliman.jpg";
import zakaria from "./imgs/zakaria.jpg";
import drAdnan from "./imgs/drAdnan.jpg";
import shada from "./imgs/shada.jpg";
import aymn from "./imgs/aymn.jpg";
import "./style/officePage.css";
function OfficePage() {
  return (
    <div>
      <PageHead title="Meet the Officers " route="Home / Office" />
      <div className="officers-style">
        <OfficerBlock
          name="Dr. Adnan Sherif"
          position="Faculty Sponsor"
          imgSrc={drAdnan}
        />
        <OfficerBlock name="Majd M. Jaaka" position="Chair" imgSrc={majd} />
        <OfficerBlock
          name="Yassen Tomokti"
          position="Vice Chair"
          imgSrc={yassen}
        />
        <OfficerBlock
          name="Zakaria Abu Aisha"
          position="Treasurer"
          imgSrc={zakaria}
        />
        <OfficerBlock
          name="Soliman Abu Aisha"
          position="Secretary"
          imgSrc={soliman}
        />
        <OfficerBlock
          name="Ayman Mohammed Algammudi"
          position="PR Officer"
          imgSrc={aymn}
        />
        <OfficerBlock
          name="Shada Abdalmajed Alzintany"
          position="Officer"
          imgSrc={shada}
        />
        <OfficerBlock name="Farah Abdalhafiz Rahil" position="Officer" />
      </div>
    </div>
  );
}
export default OfficePage;
