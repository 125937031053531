import "./style/AcmTeam.css";
function AcmTeam(props) {
  return (
    <div className="acm-team-container" id={props.teamStyle}>
      <img className="team-icon" src={props.teamIcon} alt="techIcon" />
      <img className="team-img" src={props.teamImage} alt="techImage" />
      <p className="team-text">{props.title}</p>
      <div className="acm-team-btn-container">
        <a
          href={props.teamUrl}
          target="_blank"
          rel="noreferrer"
          className="acm-team-btn"
          id={props.btnTeamStyle}
        >
          Join Team
        </a>
      </div>
    </div>
  );
}
export default AcmTeam;
