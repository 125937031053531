import { Link } from "react-router-dom";
import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import "./style/AboutChapter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
function AboutChapter() {
  const conatiner = useRef(null);
  const isInView = useInView(conatiner, { margin: "0px 0px -20px 0px" });
  const MainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      MainControls.start("visible");
    }
  }, [MainControls, isInView]);
  const pVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.2,
      },
    },
  };
  return (
    <motion.div
      variants={pVariants}
      initial="hidden"
      animate={MainControls}
      className="about-chapter-container"
    >
      <motion.div
        ref={conatiner}
        variants={pVariants}
        initial="hidden"
        animate={MainControls}
      >
        <motion.div className="about-chapter-block" variants={pVariants}>
          <motion.h3 variants={pVariants}>
            First ACM Chapter in Libya.
          </motion.h3>
          <motion.p variants={pVariants}>
            The journey of the ACM chapter at UOT began with enthusiasm and
            determination. As a student chapter, it is based in the Department
            of Computer Science at the University of Tripoli — a centre for
            innovation, learning and collaboration. The establishment of this
            chapter is an important milestone for the university and its
            students.
          </motion.p>
          <Link className="about-link-btn" to="/About">
            <FontAwesomeIcon
              className="about-icon-btn"
              icon={faArrowUpRightFromSquare}
            />
            <span> About</span>
          </Link>
          <div className="about-dots-background"></div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default AboutChapter;
