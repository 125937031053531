import acmabout from "./imgs/acmabout.png";
import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import "./style/AboutAcm.css";
function AboutAcm() {
  const conatiner = useRef(null);
  const isInView = useInView(conatiner, { margin: "0px 0px -100px 0px" });
  const MainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      MainControls.start("visible");
    }
  }, [MainControls, isInView]);
  const pVariants = {
    hidden: { scale: 0.99, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.2,
      },
    },
  };
  const cVariants = {
    hidden: { scale: 0.99, opacity: 0, y: 50 },
    visible: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <motion.div
      ref={conatiner}
      className="aboutacm-conatiner"
      variants={pVariants}
      initial="hidden"
      animate={MainControls}
    >
      <motion.div variants={cVariants} className="left-block">
        <img src={acmabout} alt="ACM ABOUT" />
      </motion.div>
      <div className="right-block">
        <motion.h2 variants={cVariants}>
          About the ACM <br /> Organization
        </motion.h2>
        <motion.p variants={cVariants} className="p1">
          ACM brings together computing educators, researchers, and
          professionals to inspire dialogue, share resources, and address the
          field's challenges. As the world’s largest computing society, ACM
          strengthens the profession's collective voice through strong
          leadership, promotion of the highest standards, and recognition of
          technical excellence. ACM supports the professional growth of its
          members by providing opportunities for life‐long learning, career
          development, and professional networking.
        </motion.p>
        <motion.p variants={cVariants} className="p2">
          ACM’s broad‐based infrastructure{" "}
          <ins>
            {" "}
            supports more than 860 professional and student chapters worldwide.
          </ins>{" "}
          These "local neighborhoods" offer opportunities for members to gain
          access to critical research and establish personal networking systems.
        </motion.p>
        <motion.a
          variants={cVariants}
          target="blank"
          href={"https://www.acm.org/about-acm/about-the-acm-organization"}
        >
          Learn More...
        </motion.a>
      </div>
    </motion.div>
  );
}

export default AboutAcm;
