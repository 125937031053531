import PageHead from "./PageHead";

function Page404() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PageHead title="404 not found" />
    </div>
  );
}
export default Page404;
